import { mapGenericAuthorableGridCards } from 'templates/GenericGridAuthorable/helper/mapGenericAuthorableGridCards';
import { GenericGridAuthorable } from './components/GenericGridAuthorable';

import type { GenericGridAuthorable as TGenericGridAuthorable } from 'server/repository/hygraph/generated/graphqlTypes';
import type { ISharedTemplateProps } from 'templates/TemplateMapper/types';
import { TrackingService } from 'service/tracking/trackingService';
import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';

interface IGenericAuthorableGrid extends ISharedTemplateProps {
  templateProps: TGenericGridAuthorable;
}

export const GenericGridAuthorableContainer = ({
  templateProps: {
    dictionary,
    cards: componentCards,
    title: heading,
    regularLayout,
    superText,
    recipeCarouselMarkUp,
  },
  difficultyCopy,
  recipeCopy,
  searchItemLabels,
  templateIndex,
}: IGenericAuthorableGrid) => {
  const { pathTranslations, userToken } = useGlobalContext();
  const containerPosition = templateIndex ? templateIndex + 1 : 1;
  const cards = mapGenericAuthorableGridCards(
    componentCards,
    dictionary ?? { dictionaryItems: [] },
    pathTranslations,
    recipeCopy ?? [],
  );
  /**
   * Used to fire the teaser event for recipe cards in generic grid authorable
   */
  const clickTeaserSeeRecipeGridContainer = (data: any, position: number) => {
    TrackingService.clickTeaserSeeRecipe({
      teaserName: data?.title,
      containerTitle: heading,
      containerPosition,
      containerType: 'grid',
      teaserPosition: position || 0,
      teaserRecipeId: data?.id,
      teaserRecipeTitle: data?.title,
      teaserRecipeDifficulty: difficultyCopy?.[data?.information?.difficulty] || 'na',
      teaserRecipeRating: data?.information?.rating,
      teaserRecipeDuration: data?.information?.totalTime,
    });
  };

  /**
   * Used to fire the teaser event for product cards in generic grid authorable
   */
  const clickTeaserSeeProductGridContainer = (title: string, position: number) => {
    TrackingService.productTeaserCardClick(
      title || 'na',
      position || 0,
      heading || 'na',
      containerPosition + 1 || 0,
      'carousel',
    );
  };

  const clickSeeCategoryOccasion = (title: string) => {
    TrackingService.clickCategoryOccasion(title || 'na');
  };
  return (
    <>
      <GenericGridAuthorable
        heading={heading}
        isRegularGrid={regularLayout}
        subHeading={superText}
        labels={searchItemLabels}
        cards={cards}
        trackingEvents={{
          clickAuthorableCard: clickTeaserSeeProductGridContainer,
          clickRecipeCard: (data: any, position: number) => {
            clickTeaserSeeRecipeGridContainer(data, position);
            TrackingService.articlePageConversionAfterSearch(
              (window.dataLayer as Record<string, string>[]) || [],
              userToken,
            );
          },
          clickOccasionCard: clickSeeCategoryOccasion,
        }}
        host={''} // TODO: needs to be fixed
        recipeCarouselMarkUp={recipeCarouselMarkUp}
      />
    </>
  );
};

export default GenericGridAuthorableContainer;
